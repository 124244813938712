import React from 'react';
import useMobileMode from '../../../hooks/useMobileMode';
import './category-information.scss';
import RichText from '../../../shared/components/atoms/rich-text/rich-text';

const CategoryInformation = ({ informacionCategoria }) => {
  const { isMobile } = useMobileMode();

  const {
    seccionInicial,
    textoImagenSegundos,
    textoTercero,
    caracteristicas,
    imagenSecundariaCaracteristicas,
    beneficios,
    textoEImagenFinalBeneficios,
    textoBeneficiosAdicional,
    textoTiposCategoriaUno,
    tiposCategoriaUno,
    textoTiposCategoriaDos,
    tiposCategoriaDos,
    textoImagenComplementario,
    textoSeccionAdicionalUno,
    imagenesDescripcionesSeccionAdicionalUno,
    textoSeccionAdicionalDos,
    esLaSeccionAdicionalDosAlineada,
    imagenesDescripcionesSeccionAdicionalDos,
    textoFinalSeccionAdicionalDos,
    textoFinal,
  } = informacionCategoria;

  return (
    <section className="f-category-infomartion-content">
      {/* Sección Inicial */}
      <div className="f-category-infomartion-content-full-size">
        {seccionInicial && (
          <div className="f-category-infomartion-content-initial-section f-category-infomartion-content-defined-size">
            {seccionInicial.imagenDelPaso && (
              <div className="f-category-infomartion-content-initial-section-img">
                <img
                  src={seccionInicial.imagenDelPaso.file.url}
                  alt={
                    seccionInicial.imagenDelPaso.description
                      ? seccionInicial.imagenDelPaso.descripcion
                      : seccionInicial.imagenDelPaso.title
                  }
                />
              </div>
            )}
            {seccionInicial.descripcionDelPaso && (
              <div className="f-category-infomartion-content-initial-section-container-text">
                <RichText
                  jsonInfo={seccionInicial.descripcionDelPaso.json}
                  className="f-category-infomartion-content-rich-text"
                />
              </div>
            )}
          </div>
        )}
      </div>
      
      {/* Sección Secundaria */}
      <div className="f-category-infomartion-content-full-size">
        {textoImagenSegundos && (
          <div className="f-category-infomartion-content-secondary-section f-category-infomartion-content-defined-size">
            {textoImagenSegundos.descripcionDelPaso && (
              <div className="f-category-infomartion-content-secondary-section-container-text">
                <RichText
                  jsonInfo={textoImagenSegundos.descripcionDelPaso.json}
                  className="f-category-infomartion-content-rich-text"
                />
              </div>
            )}
            {textoImagenSegundos.imagenDelPaso && (
              <div className="f-category-infomartion-content-secondary-section-img">
                <img
                  src={textoImagenSegundos.imagenDelPaso.file.url}
                  alt={
                    textoImagenSegundos.imagenDelPaso.description
                      ? textoImagenSegundos.imagenDelPaso.description
                      : textoImagenSegundos.imagenDelPaso.title
                  }
                />
              </div>
            )}
          </div>
        )}
      </div>

      {/* Texto tercero */}
      {textoTercero && (
        <div className="f-category-infomartion-content-full-size">
          <div className="f-category-infomartion-content-tertiary-text f-category-infomartion-content-defined-size">
            <RichText
              jsonInfo={textoTercero.json}
              className="f-category-infomartion-content-rich-text"
            />
          </div>
        </div>
      )}

      <div className="f-category-infomartion-content-full-size f-category-infomartion-content-background-blue">
        {/* Sección Características */}
        {caracteristicas && (
          <div className="f-category-infomartion-content-characteristics-section">
            {!caracteristicas.imagenDelPaso ? (
              <div className="f-category-infomartion-content-characteristics-section-content-only-text f-category-infomartion-content-defined-size">
                {caracteristicas.nombreDelPaso && (
                  <h3>{caracteristicas.nombreDelPaso}</h3>
                )}
                {caracteristicas.descripcionDelPaso && (
                  <RichText
                    jsonInfo={caracteristicas.descripcionDelPaso.json}
                    className="f-category-infomartion-content-rich-text"
                  />
                )}
              </div>
            ) : (
              <div className="f-category-infomartion-content-characteristics-section-content f-category-infomartion-content-defined-size">
                <div className="f-category-infomartion-content-characteristics-section-content-title-img">
                  {caracteristicas.nombreDelPaso && (
                    <h3>{caracteristicas.nombreDelPaso}</h3>
                  )}
                  {caracteristicas.imagenDelPaso && (
                    <div className="f-category-infomartion-content-characteristics-section-content-title-img-img">
                      <img
                        src={caracteristicas.imagenDelPaso.file.url}
                        alt={
                          caracteristicas.imagenDelPaso.description
                            ? caracteristicas.imagenDelPaso.description
                            : caracteristicas.imagenDelPaso.title
                        }
                      />
                    </div>
                  )}
                </div>
                <div className="f-category-infomartion-content-characteristics-section-content-text-img">
                  {caracteristicas.descripcionDelPaso && (
                    <RichText
                      jsonInfo={caracteristicas.descripcionDelPaso.json}
                      className="f-category-infomartion-content-rich-text"
                    />
                  )}
                  {imagenSecundariaCaracteristicas && (
                    <div className="f-category-infomartion-content-characteristics-section-content-text-img-img">
                      <img
                        src={imagenSecundariaCaracteristicas.file.url}
                        alt={
                          imagenSecundariaCaracteristicas.description
                            ? imagenSecundariaCaracteristicas.description
                            : imagenSecundariaCaracteristicas.title
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        {/* Sección Beneficios */}
        {beneficios && beneficios.descripcionDelPaso && (
          <div className="f-category-infomartion-content-benefits-section">
            {!beneficios.imagenDelPaso ? (
              <div className="f-category-infomartion-content-benefits-section-content-only-text f-category-infomartion-content-defined-size">
                {beneficios.nombreDelPaso && (
                  <h3>{beneficios.nombreDelPaso}</h3>
                )}
                {beneficios.descripcionDelPaso && (
                  <RichText
                    jsonInfo={beneficios.descripcionDelPaso.json}
                    className="f-category-infomartion-content-rich-text"
                  />
                )}
              </div>
            ) : (
              <div className="f-category-infomartion-content-benefits-section-title" > {beneficios.nombreDelPaso && (
                <h3>{beneficios.nombreDelPaso}</h3>
              )}
              <div className="f-category-infomartion-content-benefits-section-content f-category-infomartion-content-defined-size">  
                <div className="f-category-infomartion-content-benefits-section-content-title-img">
                  {beneficios.imagenDelPaso && (
                    <div className="f-category-infomartion-content-benefits-section-content-title-img-img">
                      <img
                        src={beneficios.imagenDelPaso.file.url}
                        alt={
                          beneficios.imagenDelPaso.description
                            ? beneficios.imagenDelPaso.description
                            : beneficios.imagenDelPaso.title
                        }
                      />
                    </div>
                  )}
                </div>
                <div className="f-category-infomartion-content-benefits-section-content-text">
                  {beneficios.descripcionDelPaso && (
                    <RichText
                      jsonInfo={beneficios.descripcionDelPaso.json}
                      className="f-category-infomartion-content-rich-text"
                    />
                  )}
                </div>
              </div>
            </div>)}
          </div> 
        )}

        {/* Texto Beneficios Adicional */}
        {textoBeneficiosAdicional && (
          <div className="f-category-infomartion-content-full-size">
            <div className="f-category-infomartion-content-benefits-section-aditional-text f-category-infomartion-content-defined-size">
              <RichText
                jsonInfo={textoBeneficiosAdicional.json}
                className="f-category-infomartion-content-rich-text"
              />
            </div>
          </div>
        )}

        {/* Texto e imagen Beneficios Adicional */}
        <div className="f-category-infomartion-content-full-size">
          {textoEImagenFinalBeneficios && (
            <div className="f-category-infomartion-content-benefits-section-secondary-section f-category-infomartion-content-defined-size">
              {textoEImagenFinalBeneficios.descripcionDelPaso && (
                <div className="f-category-infomartion-content-benefits-section-secondary-section-container-text">
                  <RichText
                    jsonInfo={textoEImagenFinalBeneficios.descripcionDelPaso.json}
                    className="f-category-infomartion-content-rich-text"
                  />
                </div>
              )}
              {textoEImagenFinalBeneficios.imagenDelPaso && (
                <div className="f-category-infomartion-content-benefits-section-secondary-section-img">
                  <img
                    src={textoEImagenFinalBeneficios.imagenDelPaso.file.url}
                    alt={
                      textoEImagenFinalBeneficios.imagenDelPaso.description
                        ? textoEImagenFinalBeneficios.imagenDelPaso.description
                        : textoEImagenFinalBeneficios.imagenDelPaso.title
                    }
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Sección Tipos 1 */}
      <div className="f-category-infomartion-content-full-size">
        <div className="f-category-infomartion-content-types-section f-category-infomartion-content-defined-size">
          {textoTiposCategoriaUno && (
            <RichText
              jsonInfo={textoTiposCategoriaUno.json}
              className="f-category-infomartion-content-rich-text"
            />
          )}
          {tiposCategoriaUno && (
            <div className="f-category-infomartion-content-types-section-info">
              {tiposCategoriaUno.map((type, key) => {
                return (
                  <div
                    key={key}
                    className="f-category-infomartion-content-types-section-info-type"
                  >
                    <div className="f-category-infomartion-content-types-section-info-type-img">
                      {type.imagenDelPaso && (
                        <img
                          src={type.imagenDelPaso.file.url}
                          alt={
                            type.imagenDelPaso.description
                              ? type.imagenDelPaso.description
                              : type.imagenDelPaso.title
                          }
                        />
                      )}
                    </div>
                    <div className="f-category-infomartion-content-types-section-info-type-text">
                      {type.descripcionDelPaso && (
                        <RichText
                          jsonInfo={type.descripcionDelPaso.json}
                          className="f-category-infomartion-content-rich-text"
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>

      {/* Sección Tipos 2 */}

      {(textoTiposCategoriaDos || tiposCategoriaDos) && (
        <div className="f-category-infomartion-content-full-size">
          <div className="f-category-infomartion-content-types-section f-category-infomartion-content-defined-size">
            {textoTiposCategoriaDos && (
              <RichText
                jsonInfo={textoTiposCategoriaDos.json}
                className="f-category-infomartion-content-rich-text f-category-infomartion-content-rich-text-categoria"
              />
            )}
            {tiposCategoriaDos && (
              <div className="f-category-infomartion-content-types-section-info">
                {tiposCategoriaDos.map((type, key) => {
                  return (
                    <div
                      key={key}
                      className="f-category-infomartion-content-types-section-info-type"
                    >
                      <div className="f-category-infomartion-content-types-section-info-type-img">
                        {type.imagenDelPaso && (
                          <img
                            src={type.imagenDelPaso.file.url}
                            alt={
                              type.imagenDelPaso.description
                                ? type.imagenDelPaso.description
                                : type.imagenDelPaso.title
                            }
                          />
                        )}
                      </div>
                      <div className="f-category-infomartion-content-types-section-info-type-text">
                        {type.descripcionDelPaso && (
                          <RichText
                            jsonInfo={type.descripcionDelPaso.json}
                            className="f-category-infomartion-content-rich-text"
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}

      {/* Sección Complementaria */}
      <div className="f-category-infomartion-content-full-size">
        {textoImagenComplementario && (
          <div className="f-category-infomartion-content-complementary-section f-category-infomartion-content-defined-size">
            {textoImagenComplementario.descripcionDelPaso && (
              <div className="f-category-infomartion-content-complementary-section-container-text">
                <RichText
                  jsonInfo={textoImagenComplementario.descripcionDelPaso.json}
                  className="f-category-infomartion-content-rich-text"
                />
              </div>
            )}
            {textoImagenComplementario.imagenDelPaso && (
              <div className="f-category-infomartion-content-complementary-section-img">
                <img
                  src={textoImagenComplementario.imagenDelPaso.file.url}
                  alt={
                    textoImagenComplementario.imagenDelPaso.description
                      ? textoImagenComplementario.imagenDelPaso.description
                      : textoImagenComplementario.imagenDelPaso.title
                  }
                />
              </div>
            )}
          </div>
        )}
      </div>

      {/* Sección Adicional 1 */}
      <div className="f-category-infomartion-content-full-size">
        <div className="f-category-infomartion-content-aditional-section-one f-category-infomartion-content-defined-size">
          {textoSeccionAdicionalUno && (
            <RichText
              jsonInfo={textoSeccionAdicionalUno.json}
              className="f-category-infomartion-content-rich-text"
            />
          )}
          {imagenesDescripcionesSeccionAdicionalUno && (
            <div className="f-category-infomartion-content-aditional-section-one-applications">
              {imagenesDescripcionesSeccionAdicionalUno.map(
                (application, key) => {
                  return (
                    <div
                      className="f-category-infomartion-content-aditional-section-one-applications-item"
                      key={key}
                    >
                      {application.imagenDelPaso && (
                        <div className="f-category-infomartion-content-aditional-section-one-applications-item-img">
                          <img
                            src={application.imagenDelPaso.file.url}
                            alt={
                              application.imagenDelPaso.description
                                ? application.imagenDelPaso.description
                                : application.imagenDelPaso.title
                            }
                          />
                        </div>
                      )}
                      {application.descripcionDelPaso && (
                        <RichText
                          jsonInfo={application.descripcionDelPaso.json}
                          className="f-category-infomartion-content-rich-text"
                        />
                      )}
                    </div>
                  );
                }
              )}
            </div>
          )}
        </div>
      </div>

      {(textoSeccionAdicionalDos ||
        imagenesDescripcionesSeccionAdicionalDos) && (
        <div className="f-category-infomartion-content-background-blue f-category-infomartion-content-full-size">
          {/* Texto Sección Adicional 2 */}
          {textoSeccionAdicionalDos && (
            <div className="f-category-infomartion-content-only-text f-category-infomartion-content-defined-size">
              <RichText
                jsonInfo={textoSeccionAdicionalDos.json}
                className="f-category-infomartion-content-rich-text"
              />
            </div>
          )}

          {/* Sección Adicional 2 Diseño No Alineado */}
          {!esLaSeccionAdicionalDosAlineada &&
            imagenesDescripcionesSeccionAdicionalDos && (
              <div className="f-category-infomartion-content-defined-size">
                {isMobile ? (
                  <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned">
                    <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element">
                      {imagenesDescripcionesSeccionAdicionalDos[0] && (
                        <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-left">
                          <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-left-text">
                            {imagenesDescripcionesSeccionAdicionalDos[0]
                              .descripcionDelPaso && (
                              <RichText
                                jsonInfo={
                                  imagenesDescripcionesSeccionAdicionalDos[0]
                                    .descripcionDelPaso.json
                                }
                                className="f-category-infomartion-content-rich-text"
                              />
                            )}
                          </div>
                          <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-left-img">
                            {imagenesDescripcionesSeccionAdicionalDos[0]
                              .imagenDelPaso && (
                              <img
                                src={
                                  imagenesDescripcionesSeccionAdicionalDos[0]
                                    .imagenDelPaso.file.url
                                }
                                alt={
                                  imagenesDescripcionesSeccionAdicionalDos[0]
                                    .imagenDelPaso.description
                                    ? imagenesDescripcionesSeccionAdicionalDos[0]
                                        .imagenDelPaso.description
                                    : imagenesDescripcionesSeccionAdicionalDos[0]
                                        .imagenDelPaso.title
                                }
                              />
                            )}
                          </div>
                        </div>
                      )}

                      {imagenesDescripcionesSeccionAdicionalDos[1] && (
                        <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-right">
                          <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-right-img">
                            {imagenesDescripcionesSeccionAdicionalDos[1]
                              .imagenDelPaso && (
                              <img
                                src={
                                  imagenesDescripcionesSeccionAdicionalDos[1]
                                    .imagenDelPaso.file.url
                                }
                                alt={
                                  imagenesDescripcionesSeccionAdicionalDos[1]
                                    .imagenDelPaso.description
                                    ? imagenesDescripcionesSeccionAdicionalDos[1]
                                        .imagenDelPaso.description
                                    : imagenesDescripcionesSeccionAdicionalDos[1]
                                        .imagenDelPaso.title
                                }
                              />
                            )}
                          </div>
                          <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-right-text">
                            {imagenesDescripcionesSeccionAdicionalDos[1]
                              .descripcionDelPaso && (
                              <RichText
                                jsonInfo={
                                  imagenesDescripcionesSeccionAdicionalDos[1]
                                    .descripcionDelPaso.json
                                }
                                className="f-category-infomartion-content-rich-text"
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element">
                      {imagenesDescripcionesSeccionAdicionalDos[2] && (
                        <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-left">
                          <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-left-text">
                            {imagenesDescripcionesSeccionAdicionalDos[2]
                              .descripcionDelPaso && (
                              <RichText
                                jsonInfo={
                                  imagenesDescripcionesSeccionAdicionalDos[2]
                                    .descripcionDelPaso.json
                                }
                                className="f-category-infomartion-content-rich-text"
                              />
                            )}
                          </div>
                          <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-left-img">
                            {imagenesDescripcionesSeccionAdicionalDos[2]
                              .imagenDelPaso && (
                              <img
                                src={
                                  imagenesDescripcionesSeccionAdicionalDos[2]
                                    .imagenDelPaso.file.url
                                }
                                alt={
                                  imagenesDescripcionesSeccionAdicionalDos[2]
                                    .imagenDelPaso.description
                                    ? imagenesDescripcionesSeccionAdicionalDos[2]
                                        .imagenDelPaso.description
                                    : imagenesDescripcionesSeccionAdicionalDos[2]
                                        .imagenDelPaso.title
                                }
                              />
                            )}
                          </div>
                        </div>
                      )}

                      {imagenesDescripcionesSeccionAdicionalDos[3] && (
                        <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-right">
                          <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-right-img">
                            {imagenesDescripcionesSeccionAdicionalDos[3]
                              .imagenDelPaso && (
                              <img
                                src={
                                  imagenesDescripcionesSeccionAdicionalDos[3]
                                    .imagenDelPaso.file.url
                                }
                                alt={
                                  imagenesDescripcionesSeccionAdicionalDos[3]
                                    .imagenDelPaso.description
                                    ? imagenesDescripcionesSeccionAdicionalDos[3]
                                        .imagenDelPaso.description
                                    : imagenesDescripcionesSeccionAdicionalDos[3]
                                        .imagenDelPaso.title
                                }
                              />
                            )}
                          </div>
                          <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-right-text">
                            {imagenesDescripcionesSeccionAdicionalDos[3]
                              .descripcionDelPaso && (
                              <RichText
                                jsonInfo={
                                  imagenesDescripcionesSeccionAdicionalDos[3]
                                    .descripcionDelPaso.json
                                }
                                className="f-category-infomartion-content-rich-text"
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element">
                      {imagenesDescripcionesSeccionAdicionalDos[4] && (
                        <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-left">
                          <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-left-text">
                            {imagenesDescripcionesSeccionAdicionalDos[4]
                              .descripcionDelPaso && (
                              <RichText
                                jsonInfo={
                                  imagenesDescripcionesSeccionAdicionalDos[4]
                                    .descripcionDelPaso.json
                                }
                                className="f-category-infomartion-content-rich-text"
                              />
                            )}
                          </div>
                          <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-left-img">
                            {imagenesDescripcionesSeccionAdicionalDos[4]
                              .imagenDelPaso && (
                              <img
                                src={
                                  imagenesDescripcionesSeccionAdicionalDos[4]
                                    .imagenDelPaso.file.url
                                }
                                alt={
                                  imagenesDescripcionesSeccionAdicionalDos[4]
                                    .imagenDelPaso.description
                                    ? imagenesDescripcionesSeccionAdicionalDos[4]
                                        .imagenDelPaso.description
                                    : imagenesDescripcionesSeccionAdicionalDos[4]
                                        .imagenDelPaso.title
                                }
                              />
                            )}
                          </div>
                        </div>
                      )}

                      {imagenesDescripcionesSeccionAdicionalDos[5] && (
                        <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-right">
                          <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-right-img">
                            {imagenesDescripcionesSeccionAdicionalDos[5]
                              .imagenDelPaso && (
                              <img
                                src={
                                  imagenesDescripcionesSeccionAdicionalDos[5]
                                    .imagenDelPaso.file.url
                                }
                                alt={
                                  imagenesDescripcionesSeccionAdicionalDos[5]
                                    .imagenDelPaso.description
                                    ? imagenesDescripcionesSeccionAdicionalDos[5]
                                        .imagenDelPaso.description
                                    : imagenesDescripcionesSeccionAdicionalDos[5]
                                        .imagenDelPaso.title
                                }
                              />
                            )}
                          </div>
                          <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-right-text">
                            {imagenesDescripcionesSeccionAdicionalDos[5]
                              .descripcionDelPaso && (
                              <RichText
                                jsonInfo={
                                  imagenesDescripcionesSeccionAdicionalDos[5]
                                    .descripcionDelPaso.json
                                }
                                className="f-category-infomartion-content-rich-text"
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned">
                    <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element">
                      <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-left">
                        <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-left-text">
                          {imagenesDescripcionesSeccionAdicionalDos[0] && imagenesDescripcionesSeccionAdicionalDos[0]
                            .descripcionDelPaso && (
                            <RichText
                              jsonInfo={
                                imagenesDescripcionesSeccionAdicionalDos[0]
                                  .descripcionDelPaso.json
                              }
                              className="f-category-infomartion-content-rich-text"
                            />
                          )}
                        </div>
                        <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-left-img">
                          {imagenesDescripcionesSeccionAdicionalDos[1] && imagenesDescripcionesSeccionAdicionalDos[1]
                            .imagenDelPaso && (
                            <img
                              src={
                                imagenesDescripcionesSeccionAdicionalDos[1]
                                  .imagenDelPaso.file.url
                              }
                              alt={
                                imagenesDescripcionesSeccionAdicionalDos[1]
                                  .imagenDelPaso.description
                                  ? imagenesDescripcionesSeccionAdicionalDos[1]
                                      .imagenDelPaso.description
                                  : imagenesDescripcionesSeccionAdicionalDos[1]
                                      .imagenDelPaso.title
                              }
                            />
                          )}
                        </div>
                      </div>

                      <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-right">
                        <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-right-img">
                          {imagenesDescripcionesSeccionAdicionalDos[0] && imagenesDescripcionesSeccionAdicionalDos[0]
                            .imagenDelPaso && (
                            <img
                              src={
                                imagenesDescripcionesSeccionAdicionalDos[0]
                                  .imagenDelPaso.file.url
                              }
                              alt={
                                imagenesDescripcionesSeccionAdicionalDos[0]
                                  .imagenDelPaso.description
                                  ? imagenesDescripcionesSeccionAdicionalDos[0]
                                      .imagenDelPaso.description
                                  : imagenesDescripcionesSeccionAdicionalDos[0]
                                      .imagenDelPaso.title
                              }
                            />
                          )}
                        </div>
                        <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-right-text">
                          {imagenesDescripcionesSeccionAdicionalDos[1] && imagenesDescripcionesSeccionAdicionalDos[1]
                            .descripcionDelPaso && (
                            <RichText
                              jsonInfo={
                                imagenesDescripcionesSeccionAdicionalDos[1]
                                  .descripcionDelPaso.json
                              }
                              className="f-category-infomartion-content-rich-text"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element">
                      <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-left">
                        <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-left-text">
                          {imagenesDescripcionesSeccionAdicionalDos[2] && imagenesDescripcionesSeccionAdicionalDos[2]
                            .descripcionDelPaso && (
                            <RichText
                              jsonInfo={
                                imagenesDescripcionesSeccionAdicionalDos[2]
                                  .descripcionDelPaso.json
                              }
                              className="f-category-infomartion-content-rich-text"
                            />
                          )}
                        </div>
                        <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-left-img">
                          {imagenesDescripcionesSeccionAdicionalDos[3] && imagenesDescripcionesSeccionAdicionalDos[3]
                            .imagenDelPaso && (
                            <img
                              src={
                                imagenesDescripcionesSeccionAdicionalDos[3]
                                  .imagenDelPaso.file.url
                              }
                              alt={
                                imagenesDescripcionesSeccionAdicionalDos[3]
                                  .imagenDelPaso.description
                                  ? imagenesDescripcionesSeccionAdicionalDos[3]
                                      .imagenDelPaso.description
                                  : imagenesDescripcionesSeccionAdicionalDos[3]
                                      .imagenDelPaso.title
                              }
                            />
                          )}
                        </div>
                      </div>

                      <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-right">
                        <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-right-img">
                          {imagenesDescripcionesSeccionAdicionalDos[2] && imagenesDescripcionesSeccionAdicionalDos[2]
                            .imagenDelPaso && (
                            <img
                              src={
                                imagenesDescripcionesSeccionAdicionalDos[2]
                                  .imagenDelPaso.file.url
                              }
                              alt={
                                imagenesDescripcionesSeccionAdicionalDos[2]
                                  .imagenDelPaso.description
                                  ? imagenesDescripcionesSeccionAdicionalDos[2]
                                      .imagenDelPaso.description
                                  : imagenesDescripcionesSeccionAdicionalDos[2]
                                      .imagenDelPaso.title
                              }
                            />
                          )}
                        </div>
                        <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-right-text">
                          {imagenesDescripcionesSeccionAdicionalDos[3] && imagenesDescripcionesSeccionAdicionalDos[3]
                            .descripcionDelPaso && (
                            <RichText
                              jsonInfo={
                                imagenesDescripcionesSeccionAdicionalDos[3]
                                  .descripcionDelPaso.json
                              }
                              className="f-category-infomartion-content-rich-text"
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element">
                      <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-left">
                        <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-left-text">
                          {imagenesDescripcionesSeccionAdicionalDos[4] && imagenesDescripcionesSeccionAdicionalDos[4]
                            .descripcionDelPaso && (
                            <RichText
                              jsonInfo={
                                imagenesDescripcionesSeccionAdicionalDos[4]
                                  .descripcionDelPaso.json
                              }
                              className="f-category-infomartion-content-rich-text"
                            />
                          )}
                        </div>
                          <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-left-img">
                            {imagenesDescripcionesSeccionAdicionalDos[5] && imagenesDescripcionesSeccionAdicionalDos[5]
                              .imagenDelPaso && (
                              <img
                                src={
                                  imagenesDescripcionesSeccionAdicionalDos[5]
                                    .imagenDelPaso.file.url
                                }
                                alt={
                                  imagenesDescripcionesSeccionAdicionalDos[5]
                                    .imagenDelPaso.description
                                    ? imagenesDescripcionesSeccionAdicionalDos[5]
                                        .imagenDelPaso.description
                                    : imagenesDescripcionesSeccionAdicionalDos[5]
                                        .imagenDelPaso.title
                                }
                              />
                            )}
                          </div>
                      </div>

                      <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-right">
                        <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-right-img">
                          {imagenesDescripcionesSeccionAdicionalDos[4] && imagenesDescripcionesSeccionAdicionalDos[4]
                            .imagenDelPaso && (
                            <img
                              src={
                                imagenesDescripcionesSeccionAdicionalDos[4]
                                  .imagenDelPaso.file.url
                              }
                              alt={
                                imagenesDescripcionesSeccionAdicionalDos[4]
                                  .imagenDelPaso.description
                                  ? imagenesDescripcionesSeccionAdicionalDos[4]
                                      .imagenDelPaso.description
                                  : imagenesDescripcionesSeccionAdicionalDos[4]
                                      .imagenDelPaso.title
                              }
                            />
                          )}
                        </div>
                        {imagenesDescripcionesSeccionAdicionalDos[5] && imagenesDescripcionesSeccionAdicionalDos[5] && (
                          <div className="f-category-infomartion-content-aditional-section-two-design-not-aligned-element-column-right-text">
                            {imagenesDescripcionesSeccionAdicionalDos[5]
                              .descripcionDelPaso && (
                              <RichText
                                jsonInfo={
                                  imagenesDescripcionesSeccionAdicionalDos[5]
                                    .descripcionDelPaso.json
                                }
                                className="f-category-infomartion-content-rich-text"
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

          {/* Sección Adicional 2 Diseño Alineado */}
          {esLaSeccionAdicionalDosAlineada &&
            imagenesDescripcionesSeccionAdicionalDos && (
              <div className="f-category-infomartion-content-aditional-section-two-design-aligned f-category-infomartion-content-defined-size">
                {imagenesDescripcionesSeccionAdicionalDos.map(
                  (element, key) => {
                    return (
                      <div
                        key={key}
                        className="f-category-infomartion-content-aditional-section-two-design-aligned-element"
                      >
                        <div className="f-category-infomartion-content-aditional-section-two-design-aligned-element-column-left">
                          {element.descripcionDelPaso && (
                            <div className="f-category-infomartion-content-aditional-section-two-design-aligned-element-column-left-text">
                              <RichText
                                jsonInfo={element.descripcionDelPaso.json}
                              />
                            </div>
                          )}
                        </div>
                        <div className="f-category-infomartion-content-aditional-section-two-design-aligned-element-column-right">
                          {element.imagenDelPaso && (
                            <div className="f-category-infomartion-content-aditional-section-two-design-aligned-element-column-right-img">
                              <img
                                src={element.imagenDelPaso.file.url}
                                alt={
                                  element.imagenDelPaso.descripcion
                                    ? element.imagenDelPaso.descripcion
                                    : element.imagenDelPaso.title
                                }
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            )}
          {/* Texto Sección Adicional 2 */}

          {textoFinalSeccionAdicionalDos && (
            <div className="f-category-infomartion-content-only-text-final f-category-infomartion-content-defined-size">
              <RichText
                jsonInfo={textoFinalSeccionAdicionalDos.json}
                className="f-category-infomartion-content-rich-text"
              />
            </div>
          )}
        </div>
      )}

      {/* Texto Final */}
      {textoFinal && (
        <div className="f-category-infomartion-content-full-size">
          <div className="f-category-infomartion-content-final-text f-category-infomartion-content-defined-size">
            <RichText
              jsonInfo={textoFinal.json}
              className="f-category-infomartion-content-rich-text"
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default CategoryInformation;
