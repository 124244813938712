import React from 'react';
import CategoryProducts from '../../molecules/category-products/category-products';
import CategoryInformation from '../../molecules/category-information/category-information';
import GrillProducts from '../../molecules/grill-products/grill-products';
import './products-content.scss';

const ProductsContent = props => {
  const {
    dataProducts,
    categoryIndex,
    categoryData,
    categoriesData,
  } = props;

  return (
    <>
        <>
          <CategoryProducts
            categories={categoriesData}
            category={categoryIndex}
          />
          <div className="f-products-container">
            <div className="f-products-content">
              <GrillProducts products={dataProducts} categories={categoryData} categoryIndex={categoryIndex}/>
            </div>
          </div>
          {categoryIndex !== 0 && (
            <CategoryInformation
              informacionCategoria={categoryData.informacionCategoria}
            />
          )}
        </>
    </>
  );
};

export default ProductsContent;
