import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import './rich-text.scss'

const RichText = props => {
	const { jsonInfo, className, ...params } = props
	const LOCALE = process.env.LOCALE ? process.env.LOCALE : 'es-CO';
	const optionDescription = {
		renderNode: {
			/* [BLOCKS.EMBEDDED_ASSET]: (node, next) => {
				if (node.data.target.fields) {
					return(
						<img
							className='f-rich-img'
							src={node.data.target.fields?.file[LOCALE]
								? node.data.target.fields.file[LOCALE].url
								: node.data.target.fields.file['en-US'].url}
							alt={
								node.data.target.fields.description &&
								node.data.target.fields.description['en-US']
							}
						/>)
				} else {
					return null
				}
			}, */
			[BLOCKS.PARAGRAPH]: (node, next) => {
				if (next && next[0] !== '') {
					return <p>{next}</p>
				}
			},
		},
		[INLINES.HYPERLINK]: (node, next) => (
			<a
				href={node.data.uri}
				rel='noopener noreferrer'
				target={
					node.data.uri.includes('https') || node.data.uri.includes('http')
						? '_blank'
						: '_self'
				}
			>
				{next}
			</a>
		),
		/* [INLINES.HYPERLINK]: (node, next) => {
			return node.data.uri.includes('https://jwp.io') ||
				node.data.uri.includes('youtube') ||
				node.data.uri.includes('youtu.be') ? (
				<iframe
					className='f-article-video'
					frameBorder='0'
					sandbox='allow-popups allow-same-origin allow-scripts'
					scrolling='no'
					src={
						node.data.uri.includes('youtube')
							? node.data.uri.replace('watch?v=', 'embed/')
							: node.data.uri.includes('youtu.be')
							? node.data.uri.replace('.be/', 'be.com/embed/')
							: node.data.uri
					}
					allowFullScreen
				/>
			) : (
				<a href={node.data.uri} rel='noopener noreferrer' target='_blank'>
					{next}
				</a>
			)
		}, */
	}

	return (
		<div className={(className || 'f-rich-text-container')  } {...params}>
			{documentToReactComponents(jsonInfo && jsonInfo, optionDescription)}
		</div>
	)
}

export default RichText
