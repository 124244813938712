import { withStyles } from '@mui/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React from 'react';
import useMobileMode from '../../../hooks/useMobileMode';
import './category-products.scss';
import {removeDoubleSlash} from '../../../shared/utils/urlUtil'
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil'

const datalayerMenuButtons = (categoryName, subCategory, item) => {      
  let location=''
  if (typeof window !== 'undefined') {
      location= window.location.href
  }
  pushDataLayerEvent({
    event: 'Interacciones_Menú',
    category: categoryName,
    action: 'click',
    label: subCategory || 'Ninguno',
    sub_category: subCategory || 'Ninguno',
    location: location,
    user_data: [],
    log_status: '',
  })

  pushDataLayerEvent({
    event: 'menu_click',
    action_type: 'Link',
    link_text: categoryName+' > '+subCategory,
    index: '4.'+(item+1),
    menu_name: 'Submenú'
  })
}

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      borderRadius: '50%',
      maxWidth: 6,
      width: '100%',
    },
  },
})(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const CategoryProducts = props => {

  const { categories, filterCategoryProducts, category } = props;
  const [value, setValue] = React.useState(0);
  const { isMobile } = useMobileMode();

  const renderTabs = categories => {
    return categories.map((val, index) => (
      <Tab
        component={React.forwardRef((props, ref) => (
          <button {...props}>
            <a onClick={ () => datalayerMenuButtons('Productos', val.title.title, index) } href={removeDoubleSlash('/productos/' + (categories[index].slug === null ? '' : categories[index].slug))} className={"nostyle"}>
              <span className="MuiTab-wrapper" ref={ref}>
                {val.title.title}
              </span>
            </a>
            <span className="MuiTouchRipple-root"></span>
          </button>
        ))}
        key={index}
        id={val.id}
      />
    ));
  };

  React.useEffect(() => {
    setValue(parseInt(category));
  }, []);

  return (
    <>
      {!isMobile ? (
        <div className="f-we-tabs-container">
          <div className="f-content-category">
            <StyledTabs
              className="f-tabs-category"
              value={value}
              textColor="secondary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {renderTabs(categories)}
            </StyledTabs>
          </div>
        </div>
      ) : (
        <div className="f-content-category">
          <StyledTabs
            className="f-tabs-category"
            value={value}
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {renderTabs(categories)}
          </StyledTabs>
        </div>
      )}
    </>
  );
};

export default CategoryProducts;