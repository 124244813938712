import React, { useEffect } from 'react';

import Products from '../../molecules/products/products';
import { XMasonry, XBlock } from 'react-xmasonry'; // Imports JSX plain sources
import RatingService from '../../../services/rating-service';
import useMobileMode from '../../../hooks/useMobileMode';
import { pushGtagEvent } from '../../../shared/utils/metricManagementUtil';

import './grill-products.scss';

const renderProductsMasonry = (isMobile, products, dataProducts) => {
  return (
    <XMasonry targetBlockWidth={200} /* className="f-grill-products" */>
      {products.map((item, index) => {
        const arrayCalification = dataProducts.filter(
          val => val.productId === item.contentful_id
        );

        const calification =
          arrayCalification.length > 0
            ? Math.round(arrayCalification[0].average)
            : 0;
        return (
          <XBlock key={index} width={1}>
            <Products
              isMobile={isMobile}
              key={index}
              product={item}
              calification={calification}
            ></Products>
          </XBlock>
        );
      })}
    </XMasonry>
  );
};

const gTagViewItemList = (products, categories) => {
  const items = products.map( (product, index) => {
      const {id, productName, category, commercialPartner, characteristics, productPresentations} = product
      return {
        item_id: id,
        item_name: productName?.productName||'',
        coupon: 'Ninguno',
        discount: '0',
        index: index,
        item_list_name: category?.title.title||'',
        item_list_id: category?.id||'',
        affiliation: commercialPartner||'',
        item_brand: 'Familia',
        item_category: 'Productos',
        item_variant: characteristics ? characteristics[0].title : '',
        price: '0',
        currency: 'COP',
        quantity: '0',
      }
    })
    pushGtagEvent({
    event: 'view_item_list',
    params: {
      ecommerce:{
        items: items,
      },
      item_list_name: categories ? categories.title?.title : 'Todos',
      item_list_id: categories ? categories.id : '',
      user_data: [],
      log_status: ''
    }
  })
}


const renderProducts = (isMobile, products, categories, dataProducts, categoryIndex) => {
 
  return (
    <div className="f-grill-products">
      {categories !== null && categoryIndex !== 0 ? (
        <div className="f-description-category">
          <img
            className="f-description-category-img"
            src={categories.icon !== null ? categories.icon.file.url : ''}
          ></img>
          <div className="f-description-category-title">
            <h1>{categories.title.title}</h1>
            <p className="f-description-category-description">
              {categories.categoryDescription !== null
                ? categories.categoryDescription.categoryDescription
                : ''}
            </p>
          </div>
        </div>
      ) : (
        ''
      )}
      <div className="f-products-grid">
        {renderProduct(isMobile, products, dataProducts)}
      </div>
    </div>
  );
};

const renderProduct = (isMobile, products, dataProducts) => {
  const arrProducts = products.sort((a, b) => a.updatedAt - b.updatedAt);

  return products.map((item, index) => {
    const arrayCalification = dataProducts.filter(
      val => val.productId === item.contentful_id
    );

    const calification =
      arrayCalification.length > 0
        ? Math.round(arrayCalification[0].average)
        : 0;

    return (
      <Products
        isMobile={isMobile}
        key={index}
        index={index}
        product={item}
        calification={calification}
      ></Products>
    );
  });
};

const GrillProducts = props => {
  const [dataProducts, setDataProducts] = React.useState([]);
  const { products, categories, categoryIndex } = props;
  const { isMobile } = useMobileMode();

  const productsPrincipal = products.filter(
    val => val.isNewProduct || val.hasOffer || val.autoAdvertising
  );

  const productsCommons = products.filter(
    val => !val.isNewProduct && !val.hasOffer && !val.autoAdvertising
  );

  const productsGrill = [...productsPrincipal, ...productsCommons];

  useEffect(() => {
    RatingService.getProductAvg().then(data => {
      if (data.status === 200) {
        setDataProducts(data.data);
      }
    });
    gTagViewItemList(products, categories)
  }, []);

  return (
    <>
      {isMobile
        ? renderProductsMasonry(isMobile, productsGrill, dataProducts)
        : renderProducts(isMobile, productsGrill, categories, dataProducts, categoryIndex)}
    </>
  );
};

export default GrillProducts;
