import React, { useState } from 'react';
import SEO from '../../../shared/components/seo';
import Layout from '../../organisms/layout/layout';
import ProductsContent from '../../organisms/products-content/products-content';
import './products.scss';

const Products = props => {

  const categoryIndex = parseInt(props.pageContext.currentCategoryIndex);
  const categoryData = props.pageContext.currentCategoryData || {};
  let dataProducts = props.pageContext.currentCategoryProducts;


  const { title, metaTitulo, metaDescripcion, metaDescription, metaTitle } = categoryData;
  const [modalActive, setmodalActive] = useState(null)

  const menuActive = 2;

  const classes = {
    main: 'f-main-products',
    footer: 'f-footer-products',
  };
  return (
    <>
      <SEO
        title={categoryData.metaTitulo ? metaTitulo : metaTitle ? metaTitle : (title ? title.title : '') }
        description={categoryData.metaDescripcion?.metaDescripcion ? metaDescripcion?.metaDescripcion : metaDescription ? metaDescription.metaDescription : ''}
        slug={props.path}
      />
      <Layout menuActive={menuActive} classes={classes}
        setmodalActive={setmodalActive}
        modalActive={modalActive} categoryIndex={categoryIndex}
        title={categoryData.metaTitle}
        slug={props.path}
      >
        <ProductsContent
          categoriesData={props.pageContext.categoriesData}
          dataProducts={dataProducts}
          categoryData={categoryData}
          categoryIndex={categoryIndex}
        />
      </Layout>
    </>
  );
};

export default Products;
